import { useState } from "react";
import { MessageCircle } from "lucide-react";

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Chatbot Icon Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition"
      >
        <MessageCircle size={24} />
      </button>
      
      {/* Chatbot Iframe */}
      {isOpen && (
        <div className="fixed bottom-16 right-4 w-80 h-[500px] bg-white shadow-xl rounded-lg border border-gray-300 overflow-hidden">
        <iframe
    src="https://www.chatbase.co/chatbot-iframe/7ZjGZOSaaMQF6r23HDSJT"
    width="100%"
    style={{height: "100%"}}
    frameBorder="0"
></iframe>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
